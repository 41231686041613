// import Swiper JS

import(/* webpackPrefetch: true */ /* webpackChunkName: 'swiper' */ 'root/utils/swiper.js');
import $ from 'jquery';
import '../utils/import-jquery';
import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js';
$(document).ready(function () {
  $('.project-detail .read-more-link').click(function () {
    $('.project-detail .full-description').slideToggle();
    $(this).hide();
    $('.project-detail .read-more-close').show();
  });
  $('.project-detail .read-more-close').click(function () {
    $('.project-detail .full-description').slideToggle();
    $(this).hide();
    $('.project-detail .read-more-link').show();
  });
});
$(function () {
  Fancybox.bind('#gallery .gallery-item', {});
});
